.search__mobile{
    margin-top: 10px;
    margin-bottom: 20px;
}

.search__mobile__form{
    display: flex;
}

.search__mobile__form input{
    box-sizing: content-box;
    height:  36px;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
}

.search__mobile__form button{
    box-sizing: content-box;
    background: rgb(240, 240, 240);
    color: gray;
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
    margin-left: 4px;
    height: 44.6px;
    border-top: none;
    border-left: none;
    border-right: 2px solid gray;
    border-bottom: 2px solid gray;
}