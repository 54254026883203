.pagination{
    display: flex;
    justify-content: center;
}

.pagination__body{
    display: flex;
    padding: 10px;
    border-radius: 12px;
    align-items: center;
}

.pagination__element{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-radius: 12px;
    font-size: 24px;
    background-color: rgb(245, 245, 245);;
    margin-left: 12px;
}

.pagination__element a{
    text-decoration: none;
    color: rgb(209,0,120);
    font-weight: 500;
}


.current-page{
    border-color: rgb(209,0,120);
}

@media(max-width : 767px){
    .pagination{
        margin-top: 12px;
    }
}