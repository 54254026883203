.product__content{
    padding: 20px;
    display: block;
}

.product__card{
    display: inline-block;
    border-right: 1px solid rgb(235, 235, 235);
    width: 25%;
    border-bottom: 1px solid lightgray;
    padding: 20px;
}

.product__card-body{}

.product__img{
    display: flex;
    justify-content: center;
}

.product__img img{
    height: 250px;
    width: 250px;
}

.product__title a{
    text-decoration: none;
    font-size: 16px;
    color: #464445;;
}

.product__category{
    color: gray;
    font-weight: 400;
    display: flex;
    justify-content: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.product__price{
    color: rgb(93,20,76);
    font-size: 20px;
}

.product__add-to-cart-button{
    margin-top: 20px;
}

.product__add-to-cart-button button{
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
}

@media(max-width : 767px){
    .product__content{
        padding:0;
        display: block;
    }
    .product__card{
        padding: 10px;
        display: block;
        border: 1px solid rgb(235, 235, 235);
        width: calc(100% - 20px);
    }
}