.register__content{
    padding: 20px;

}

.register__blocks{
    display: flex;
    justify-content: space-between;
}

.register-closed{
    display: none;
}