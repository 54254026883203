.product__detail{
    display: grid;
    grid-template-areas: 'img desc';
    grid-template-columns: 400px auto;
}

.shop__card-closed{
    display: none;
}

.shop__card-blured{
    filter: blur(2px);
}

.detail__image{
    grid-area: img;
    display: flex;
    justify-content: center;
}

.detail__image img{
    width: 400px;
    height: 400px;
}

.detail__info{
    grid-area: desc;
}

.detail__title{
    font-size: 26px;
    color: rgb(70, 68, 69);
    margin-bottom: 12px;
}

.detail__price{
    font-size: 24px;
    color: rgb(70, 68, 69);
    margin-bottom: 12px;
}

.detail__button button{
    font-size: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
}

.detail__table{
    margin-top: 10px;
}

.table{
    width: 50%;
}

.table tr{
    padding-top: 10px;
}
.table th{
    text-align: left;
    color: gray;
    font-weight: 400;
    padding-top: 10px;
}

.table td{
    text-align: left;
    color: rgb(70, 68, 69);
}

h2{
    color: rgb(70, 68, 69);
}

.detail__description{
    color: rgb(70, 68, 69);
    font-weight: 400;
}

.shop__card{
    width: 100%;
    background-color: rgb(240, 240, 240);
    border-radius: 4px;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    margin-bottom: 10px;
}

.shop__card-body{
    font-size: 18px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: rgb(70, 68, 69);
}
.shops__dropdown{
    display: flex;
    justify-content: center;
    width: 100%;
}

.shops__dropdown-btn{
    width: 18px;
    height: 18px;
    border-right: 3px solid gray;
    border-bottom: 3px solid gray;
    transform: rotate(45deg);
}

.btn-rotated{
    transform: rotate(-135deg);
}
.dnone{
    display: none;
}
@media(max-width : 767px){
    .product__detail{
        display: block;

    }

    .table{
        width: 100%;
    }

    .table td{
        text-align: right;
        border: 5px solid white;
    }

    .table th{
        text-align: left;
        color: gray;
        font-weight: 400;
        padding-top: 0px;
    }

    .shop__card-body{
        display: block;
    }

}
