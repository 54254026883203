.cart{
    position: relative;
}
.dnone{
    display: none;
}
.cart__body{}

.title{
    margin-bottom: 20px;
    font-size: 28px;
    color: #464445;
}

.cart__card{
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 140px;
    border-radius: 12px;
    border: 1px solid lightgray;
    margin-bottom: 10px;
}

.card__body{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card__info{
    display: grid;
    grid-template-areas: 'img txt';
}

.card__info img{
    grid-area: img;
    width: 100px;
}

.card-text{
    grid-area: txt;
}

.card-text-title{
    max-width: 350px;
    margin-bottom: 12px;
    font-size: 20px;
    color: rgb(93,20,76);
    font-weight: 400;
}

.card-subtitle{}

.card__price{
    color: #464445;
    font-size: 20px;
}

.card-delete{
    color: gray;
    font-size: 30px;
    font-weight: 400;
    cursor: pointer;
}

.add-to-cart{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    font-size: 24px;
    width: 80px;
    height: 35px;
    background-color: rgb(209,0,120);
    border-radius: 4px;
    border-bottom: 2px solid rgb(93,20,76);
    border-right: 2px solid rgb(93,20,76);
}

.total{
    width: 30%;
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}
.total__body{
    padding: 12px;

}
.total__button{
    margin-top: 10px;
}
.total__button button{
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
}
.total__quantity{
    display: flex;
    justify-content: space-between;
}

.line{
    border-bottom: 2px solid lightgray;
    width: 80px;
}

.total__price{
    display: flex;
    justify-content: space-between;
}

.empty__cart{
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

.empty__cart-title{
    font-size: 28px;
    color: #464445;
    display: flex;
    justify-content: center;
}
.catalog__button{
    display: flex;
    justify-content: center;
}
.catalog__button button{
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
}

.empty__cart-image{
    display: flex;
    justify-content: center;
}
.empty__cart-image img{
    width: 200px;
}

.empty__cart-body{
    justify-content: center;
}

.empty__cart-text{
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
}
.empty__cart-text div{
    width: 70%;
    text-align: center;
    color: #464445;
}

.total__title{
    font-size: 26px;
    color: #464445;
    margin-bottom: 10px;
}

.total__quantity{
    font-size: 20px;
    color: #464445;
}

.total__price{
    font-size: 20px;
    color: #464445;
}

@media(max-width : 767px){
    .cart__card{
        height: auto;
        padding-bottom: 10px;
    }
    .card__body{
        display: block;
    }

    .card__info img{
        width: 150px;
    }
    .add-to-cart-btn-mb{
        margin-top: 10px;
    }
    .total{
        width: 100%;
    }
}