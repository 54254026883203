@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Signika&display=swap');


body{
    font-family: 'Signika', sans-serif;
    font-weight: bold;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
}
a{
    text-decoration: none;
    color: white;
}
@media(min-width : 768px){
.header{
    width: 90%;
    left: 5%;
    height: 75px;
    border-color: rgb(240, 240, 240);
    background-color: white;

    z-index: 55; position: relative;
}
.header__body{
    padding: 4px;
    background-color: white;

    z-index: 45; position: relative;

}
.header__content{
    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 2;
}
.header__logo img{
    height: 60px;

    z-index: 21;
}

.search{
    z-index: 21;
    width: 30%;
}
.search__form{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search__form input{
    height: 36px;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 100%;
    font-family:Calibri;
    font-weight: bold;
    font-size: 18px;
}
.search__form button{
    height: 40px;
    background: rgb(240, 240, 240);
    color: gray;
    border-radius: 4px;
    font-family:Calibri;
    font-weight: bold;
    font-size: 18px;
    margin-left: 4px;
    border-top: none;
    border-left: none;
    border-right: 2px solid gray;
    border-bottom: 2px solid gray;

}
.search__form button:active{
    border-top: 2px solid rgb(93,20,76);
    border-left: 2px solid rgb(93,20,76);
    border-right: none;
    border-bottom: none;
}
.header__text{
    width: 25%;
    justify-content: space-around;
    display: flex;
    z-index: 21;
}
.header__text div{
    margin-left: 0px;
    font-size: 20px;
    color: gray;
}
.cart img{
    width: 46px;
}
.cart__quantity{
    position: absolute;
    top: 17px;
    right: 21px;
    font-size: 14px;
    justify-content: center;
    display: flex;
    width: 15px;
}

.cart__quantity span{
    font-size: 14px;

}
.header__catalog{
    width: 100%;
    height: 50px;

    position: relative; z-index: 20;
}
.header__catalog-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    position: relative; z-index: 30
}
.header__catalog__button{
    z-index: 10;
    width: 10%;
    height: 100%;
    background-color: rgb(93,20,76);
    display: flex;
    align-items: center;
    font-size: 16px;
    color: white;
    justify-content: center;
    align-items: center;
    border-top-left-radius:  12px;
    position: relative;
    border-bottom-left-radius:  12px;
}
.rectangle{
    transition-duration: 200ms;
    width: 16px;
    height: 16px;
    background-color: rgb(93,20,76);
    position: absolute; z-index: 2;
}
.header__catalog_categories{
    width: 90%;
    height: 100%;
    background-color: rgb(209,0,120);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top-right-radius:  12px;
    border-bottom-right-radius:  12px;
    z-index: 9
}
.header__catalog_categories div{
    font-size: 16px;
    color: white;
}
.header__catalog-submenu{
    transition-duration: 500ms;
    position: absolute;
    top: -650px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
}

input{
    color: #d10078;
    font-size: 16px;
}
.submenu__body{
    width: 100%;
    background: rgb(240, 240, 240);
    border-bottom:2px solid lightgray;
    border-bottom-right-radius:  12px;
    border-bottom-left-radius:  12px;  
}

.submenu__content{
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    font-size: 18px;
}

.content__block-left{
    margin-right: 20px;
}

.content__block-right{
    width: 50%;
    padding-right: 10px;
}

.submenu__content-title{
    margin-bottom: 12px;
    color: rgb(93,20,76);

}
.submenu__content__block{
    font-size: 14px;
    line-height: 28px;
    margin-top: 10px;
    word-wrap: break-word;    
}
.submenu__content-subtitle{
    margin-right: 14px;
    color: rgb(209,0,120);
    font-weight: 500;
}
.dropdown-opened{
    transition-duration: 500ms;
    top:50px;
}

.rectangle-opened{
    transition-duration: 200ms;
    transform: rotate(45deg);
    top: 42px;
}
.catalog_btn-opened{
    border-bottom-left-radius:  0;

}
.tape_btn-opened{
    border-bottom-right-radius:  0;

}

}


.container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.container__body{
    width: 90%;
}


@media(min-width : 768px) and (max-width : 1024px) {
	.header__catalog_categories div{
        font-size: 16px;
    }
    .header__catalog__button{
        font-size: 16px;
        width: 15%;
    }
    .header__text div{
        font-size: 16px;
    }
    .submenu__content{
        display: block;
    }

    .content__block-right{
        width: 100%;
        padding-right: 10px;
    }

    .header__catalog_categories {
        width: 75%;
    }
    
    .sorting__tag  {
        margin-right: 8px;
    }
    .product__info-content {
        padding: 0px;
    }
}

@media(max-width:1024px; min-width:768px){
    
}
/* @media(max-width : 767px){
    .header{}
    .header__body{}
    .header__content{
        display: flex;
        justify-content: center;
    }
    .header__logo img{
        width: 200px;
    }
    .search{
        display: none;
    }

    .header__text{
        display: none;
    }
    .header__catalog{
        display: flex;
        justify-content: center;
    }

    .header__catalog-content{
        width: 90%;
    }

    .header__catalog__button {
        padding: 4px;
        display: flex;
        align-items: center;
        font-size: 20px;
        background-color: rgb(209,0,120);
        color: white;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;

    }
    .header__catalog_categories{
        background-color: rgb(93,20,76);
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        color: white;
        padding: 10px;
    }
    .header__catalog_categories div{
        margin-bottom: 10px;
    }
    .header__catalog-submenu{
        display: none;
    }
    .cart{
        display: none;
    }
} */