.product__info{
    border:1px solid lightgray;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-left: none;

}

.product__info-content{
    padding: 20px;
}
.info__title{
    display: flex;
}
.breadcrumbs{}

.breadcrumbs__link{
    color: rgb(209,0,120);
    text-decoration: none;
    font-weight: 500;
}

.info__title{}

.product__tags{ 
    display: flex;
    margin-top: 20px;
}

.tag{
    margin-right: 20px;
    border:1px solid lightgray;
    border-radius: 4px;
}

.tag:hover{
    margin-right: 20px;
    border:1px solid rgb(209,0,120);
    color: rgb(209,0,120);
    cursor: pointer;
    border-radius: 4px;
}

.tag__body{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 500;
    color: #464445;
}

.tag__body:hover{
    color: rgb(209,0,120);
}

.sorting{
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.sorting__title{
    margin-right: 8px;
    color: rgb(162, 162, 162);
}

.sorting__tag{
    margin-right: 20px;
    background-color: #e7e7e7;
    border-radius: 4px;
    cursor: pointer;
}

.sorting__tag__body:hover{
    color:rgb(209,0,120);
}
.sorting__tag__body{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 500;
    color: #464445;
}
.info__title-title{
    font-size: 26px;
    color: #464445;
    word-break: break-all;
}

.info__title-title-name{
    font-size: 26px;
    color: #464445;
    word-break: break-all;
    margin-left: 8px;
}

@media(max-width : 767px){
    .info__title-title-name{
        font-size: 26px;
        color: #464445;
        word-break: break-all;
        margin-left: 0;
    }
    .info__title{
        display: block;
    }
    .info__title-title{
        font-size: 26px;
        color: #464445;
        word-break: break-all;
    }
    .product__info{
        border: none;
        border: 1px solid lightgray;
        border-radius: 12px;
        padding: 10px;
        margin-bottom: 10px;
        background-color: rgb(245, 245, 245);
    }
    .product__info-content{
        padding: 0;
    }

    .product__tags {
        display: block;
    }
    .tag{
        display: inline-block;
        margin-bottom: 10px;
        background-color: white;
    }
    .sorting{
        position: relative;
        display: block;
        max-height: 0px;
        overflow: hidden;
        margin-top: 0;
        transition-duration: 500ms;
    }

    .sorting__title{
        display: none;
    }

    .sorting__tag{
        margin-right: 0;
        margin-top: 10px;
    }
    
    .sorting-opened{
        max-height: 500px;
        transition-duration: 1500ms;
    }
    .filter__mobile{
        padding: 10px;
        width: calc(49.5% - 24px);
        background-color: white;
        color: rgb(209,0,120);
        border: 1px solid rgb(209,0,120);
        border-radius: 4px;
    }

    .sorting__mobile{
        padding: 10px;
        width: calc(49.5% - 20px);
        background-color: white;
        color: rgb(209,0,120);
        border: 1px solid rgb(209,0,120);
        border-radius: 4px;
    }
    .dropdown__filters{
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
}