.age__popup{
    position: fixed;
    background-color: rgba(0.1, 0.1, 0.1, 0.7);
    width: 100%;
    z-index: 999999;
    height: 100%;
}

.age__popup-content{
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.age__popup-body{
    background-color: white;
    width: 45%;
    border-radius: 12px;
    padding: 10px;

    color: #464445;
}

.age__popup-title{
    padding: 10px;
    background-origin: content-box;
    background-clip: padding-box;
    font-size: 24px;
    margin-bottom: 12px;
}

.age__popup-text{
    padding: 10px;

    font-weight: 500;
}

.age__popup-button{
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
    margin-top: 12px;
}

.age__popup-button:active{
    border-left: 2px solid rgb(93,20,76);
    border-top: 2px solid rgb(93,20,76);
    border-right: none;
    border-bottom: none;
}

@media(max-width : 767px){
    .age__popup-body{
        width: 100%;
    }
}
