.footer{
    background: #5d144c;
    border-radius: 12px;
    /* border: 1px solid lightgray; */
    width: 90%;
    position: relative;
    left: 5%;
}

.footer__body{
    padding: 20px;
    display: flex;
    justify-content: space-around;
}
.block__title{
    color: lightgray;
    font-size: 20px;
    margin-bottom: 12px;
}

.block__subtitle{
    margin-bottom: 12px;
    color: white;
    font-size: 16px;
}

.block__subtitle a{
    color: white;
    text-decoration: none;
}
@media(max-width : 767px){
    .footer__body{
        display: block;
    }
    .block__title{
        font-size: 20px;
        margin-top: 30px;
    }
}