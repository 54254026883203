.container{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
    min-height: 500px;
}
.container__body{
    width: 90%;
}

.product__grid{
    display: grid;
    grid-template-areas: 'nav product';
    grid-template-columns: 250px auto;
}

.product__grid_filters{
    display: grid;
    grid-area: nav;
}

.product__grid_products{
    display: grid;
    /* margin-left: 20px; */
    grid-area: product;
}

@media(max-width : 767px){
    .container{
        margin-top: 0;
        height: 100%;
    }

    .product__grid{
        display:block;
    }
    
}