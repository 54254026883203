.contact-form__input p{
    margin-bottom: 2px;
}

.contact-form__input{
    color: #464445;
}

.contact-form__input input{
    height: 36px;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 50%;
    font-family: Calibri;
    font-weight: bold;
    font-size: 18px;
}

.user-order{
    background-color: rgb(245, 245, 245);
    width: 50%;
    margin-bottom: 12px;
    border-radius: 12px;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.user-order-body{
    padding: 10px;
}

.user-order_body{
    padding-left: 10px;
}

.order__title{
    font-size: 22px;
    color: #464445;
    margin-bottom: 8px;
}

.order__item{
    color: rgb(209,0,120);
    margin-left: 10px;
    margin-bottom: 12px;

}

.create{
    margin-top: 12px;
    font-size: 22px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
}

@media(max-width : 767px){
    .contact-form__input input{
        width: 100%;
    }
    .user-order{
        width: 100%;
    }
    
}
