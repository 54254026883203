.add-to-cart-button button{
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;
}

.add-to-cart{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    font-size: 24px;
    width: 80px;
    height: 35px;
    background-color: rgb(209,0,120);
    border-radius: 4px;
    border-bottom: 2px solid rgb(93,20,76);
    border-right: 2px solid rgb(93,20,76);
}

.remove{
    cursor: pointer;
}

.add{
    cursor: pointer;
}