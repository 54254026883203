.choose__shop{
    color: #464445;
}
.choose-shop-body{}
.choose-shop-title{
    color: #464445;
    font-size: 28px;
    margin-bottom: 20px;
}
.choose-shop__cards{}
.choose-shop__card{
    width: 100%;
    background: rgb(245, 245, 245);
    border: 1px solid lightgray;
    border-radius: 12px;
    font-size: 18px;
    margin-bottom: 12px;
}
.choose-shop-card_body{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
}

.shop__card-button button{
    background-color: #d10078;
    border: 2px solid #5d144c;
    border-left: none;
    border-radius: 4px;
    border-top: none;
    color: #fff;
    font-size: 18px;
    padding: 8px;
}

.shop__card-quantity{
    color: #d10078;
    cursor: pointer;
}

.choose-shop-card__detail{
    padding: 10px;
}

.card-detail__body{

}

.card-detail__cards{
    padding: 4px;
}

.cart-detail__item{
    margin-bottom: 4px;
    background-color: white;
    border-radius: 12px;
    border: 1px solid lightgray;
}
.cart-detail__item-body{
    display: grid;
    grid-template-areas: 'img data';
    grid-auto-columns: 80px auto;
    padding: 4px;
}

.cart-detail__item-img{
    grid-area: img;
    width: 80px;
    height: 80px;
}

.shop__card-adress{
    width: 300px;
}
.cart-detail__item-data{
    grid-area: data;
}

@media(max-width : 768px){
    .choose-shop-card_body{
        display: block;
    }

    .choose-shop-card_body div{
        margin-top: 10px;
    }
}