.header-mb{

}
.header-mb__body{
    position: relative;
}

.header-mb__nav{
    position: absolute;
    z-index: 40;
    width: 100%;
    height: 100vh;
    background-color: white;
}
.header-mb-line{
    background-color: rgb(209,0,120);;
    display: flex;
    align-items: center;
    color: white;
    padding: 4px;
    padding-left: 6px;
    font-size: 20px;
}

.header-mb-line img{
    width: 32px;
}

.nav__element{
    background-color: white;
    padding: 10px;
    color: rgb(93,20,76);
    font-size: 20px;
    border-bottom: 1px solid lightgray;


}

.nav__arrow{
    width: 8px;
    height: 8px;
    border-bottom: 2px solid gray;
    border-right: 2px solid gray;
    transform: rotate(45deg);
    transition-duration: 500ms;
}
.nav__element-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.nav__dropdown{
    width: 100%;
    font-size: 18px;
    margin-left: 10px;
    margin-top: 10px;
    transition-duration: 500ms;
    max-height: 500px;
}
.nav__dropdown div{
    margin-bottom: 10px;
}
.header-mb__logo{
    display: flex;
    justify-content: center;
}

.header-mb__logo img{
    width: 200px;
}
.header-mb__menu{
    width: 100%;
    display: flex;
    justify-content: center;
}
.menu__body{
    width: 90%;
}

.catalog-line{
    background-color: rgb(209,0,120);;
    display: flex;
    align-items: center;
    color: white;
    padding: 4px;
    padding-left: 6px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    font-size: 20px;
}

.catalog-line img{
    width: 32px;
}

.list-categories{
    background-color: rgb(93,20,76);
    color: white;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.list-categories__content{
    border-bottom: 1px solid gray;
    padding-left: 10px;
    padding-top: 10px;

}

.list-categories div{
    margin-bottom: 10px;
}

.show__subcategories{
    display: flex;
    justify-content: center;
    padding-bottom: 6px;
}

.show__subcategories div{
    width: 16px;
    height: 16px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);

}

.closed{
    max-height: 0;
    overflow: hidden;
    transition-duration: 500ms;
    margin-top: 0;
}

.header-fixed{
    position: fixed;
}

.arrow-opened{
    transform: rotate(-135deg);
    transition-duration: 500ms;
}