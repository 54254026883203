.product__filters{
    height: 100%;
    border: 1px solid lightgray;
    border-top-left-radius: 12px;
    background-color: rgb(245, 245, 245);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.product__filters-body{}

.filter{}

.filter__body{
    padding: 12px;
}

.filter__title{
    color: #464445;
    font-size: 22px;
}

.filter__content{
    border-bottom: 1px solid lightgray;
    padding-bottom:10px;
}

.filter__input{
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #464445;
    font-weight: 500;
}

.filter__input input{
    width: 20px;
    height: 20px;
    
}

.filter__input input[type=checkbox]:checked {
    accent-color: rgb(209,0,120);
}

.filters__button button{
    background-color: lightgray;
    border: 2px solid gray;
    border-left: none;
    border-radius: 4px;
    border-top: none;
    color: #fff;
    font-size: 18px;
    padding: 8px;
}

@media(max-width : 767px){
    .product__filters{
        border: none;
        position: relative;
        max-height: 0px;
        overflow: hidden;
        width: 100%;
        border-top-left-radius: 0;
        transition-duration: 500ms;
        margin-bottom: 10px;

    }

    .filters-showed{
        max-height: 1500px;
        transition-duration: 1500ms;
    }
    
}