.user__input input{
    height: 36px;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 50%;
    font-weight: bold;
    font-size: 18px;
}

.user__input{
    color: #464445;    
    margin-bottom: 10px;

}

.user__button button{
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;

}

.user__button button:active{
    border-right: none;
    border-bottom:none;
    border-top:  2px solid rgb(93,20,76);
    border-left:  2px solid rgb(93,20,76);
}

@media(max-width : 767px){
    .user__input input{
        width: 100%;
    }
}