.login{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

.login__body{
    min-width: 400px;
    background-color: #fff;
    position: relative;
}

.login__cross{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
}

.login__content{
    padding: 20px;
}

.login__form{
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
}

.login__title{
    font-size: 24px;
    margin-bottom: 20px;
    color: #464445;
}

.login__input p{
    margin-bottom: 4px;
    color: #464445;
}

.login__input input{
    width: 100%;
    height: 36px;

}

.login__button button{
    font-size: 22px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 4px;
    border-right: 2px solid rgb(93,20,76);
    border-bottom: 2px solid rgb(93,20,76);
    background-color: rgb(209,0,120);
    color: white;

}

.register__buton{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.register__buton button{
    background-color: white;
    font-size: 18px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-color: rgb(209,0,120);
    color: rgb(209,0,120);
}

.login-opened{
    display: flex;
}

.login-closed{
    display: none;
}


@media(max-width : 767px){
    .login__body{
        width: 100%;
        height: 100%;
    }
}